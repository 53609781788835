import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { KVP } from "src/app/enums/constants";
import { Order, OrderStatus } from "src/app/services/API.service";
import { AuthService } from "src/app/services/auth.service";
import { threadId } from "worker_threads";

@Component({
  selector: "app-order-table",
  templateUrl: "./order-table.component.html",
  styleUrls: ["./order-table.component.scss"],
})
export class OrderTableComponent implements OnInit, OnChanges {
  @Input() orders: Order[];
  filteredOrders: Order[];
  @Output() editClicked = new EventEmitter<Order>();
  @Output() deleteClicked = new EventEmitter<Order>();
  @Output() viewDetails = new EventEmitter<Order>();
  @Output() SubmitOrder = new EventEmitter<Order>();
  @Output() newOrderClicked = new EventEmitter<Order>();
  @Output() refreshData = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  statuses: KVP[] = [];

  search: string = "";
  Statuses: string[] = [];

  displayedColumns: string[] = [
    "orderNo",
    "edit",
    "customerReference",
    "orderNotes",
    "price",
    "dateOrdered",
    "dateDelivered",
    "datePaid",
    "view",
    "status",
    "delete"
  ];

  mcr: any;
  ConfirmOrder: boolean;
  orderToConfirm: Order;
  orderToConfirmPrice;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.FilterResults();
  }

  ngOnInit(): void {

    this.Statuses = ["quote", "ordered", "progress", "delivered"];
    this.statuses = this.convertEnumToKVP(BenditStatuses);
    this.FilterResults();
  }

  RefreshData() {
    this.refreshData.emit();
  }

  FilterResults() {
    if (this.orders) {
      this.filteredOrders = this.orders.sort(
        (a, b) => a.orderNo < b.orderNo ? 1 : -1
      );
    }

    if (this.route.queryParams) {
      this.route.queryParams.subscribe((params) => {
        if (params.orderStatus) {
          this.Statuses = params.orderStatus;
        }
      });
    }
    if (this.orders) {
      this.SetFilter();
    }
    // console.log("orders array", this.orders);
  }

  convertEnumToKVP(myenum): KVP[] {
    let kvps: KVP[] = [];
    for (const kvpKey in myenum) {
      if (!isNaN(Number(kvpKey))) {
        kvps.push({ key: myenum[kvpKey], value: myenum[kvpKey] } as KVP);
      }
    }
    return kvps;
  }

  ViewDetails(order: Order) {
    this.viewDetails.emit(order);
  }

  // filterOrders() {
  //   this.filteredOrders = this.orders.filter(f => f.orderStatus === this.status
  //     && JSON.stringify(f).toLowerCase().indexOf(this.search.toLowerCase()) !== -1 );

  // }

  SetFilter() {
    this.mcr = new MatTableDataSource<Order>(this.filteredOrders);
    this.mcr.paginator = this.paginator;

    this.mcr.filterPredicate = (data: Order, filter: string) => {
      const searchData = JSON.stringify(data).toLowerCase();
      return (
        this.Statuses.includes(data.orderStatus) &&
        (filter === "REFRESH" || searchData.includes(filter))
      );
    };

    this.refresh();
  }

  refresh() {
    if (
      this.search !== undefined &&
      this.search !== null &&
      this.search !== ""
    ) {
      this.mcr.filter = this.search.trim().toLowerCase();
    } else {
      this.mcr.filter = "REFRESH";
    }
  }

  Edit(order: Order) {
    this.editClicked.emit(order);
  }

  Delete(order: Order) {
    if (order.orderStatus === "quote" && order.orderItems.items.length === 0) {
      this.deleteClicked.emit(order);
    }
  }
  AddNewOrder() {
    this.newOrderClicked.emit(undefined);
  }

  SubmitOrderToBendit(order: Order) {
    this.SubmitOrder.emit(order);
  }

  cancelClicked() {
    this.ConfirmOrder = false;
  }

  actionClicked(order: Order) {
    this.SubmitOrderToBendit(order);
    this.ConfirmOrder = false;
  }

  placeOrderConfirmed(order: Order) {
    this.orderToConfirm = order;
    this.ConfirmOrder = true;
    this.orderToConfirmPrice = (this.orderToConfirm.price - this.orderToConfirm.discount).toFixed(2);
  }
  toAccount() {
    this.router.navigate(["/account-home"]);
  }


  GetOrderLineTotal(order: Order) {
    let totalItems = 0;
    order.orderItems.items.forEach((item) => {
      totalItems += item.quantity;;
    });
    return totalItems;
  }
}

export enum BenditStatuses {
  "quote",
  "ordered",
  "progress",
  "delivered",
}
