import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import {
  APIService,
  CreateOrderInput,
  Customer,
  Order,
  OrderStatus,
} from "src/app/services/API.service";
import { API_CWSService } from "src/app/services/API_CWS.service";
import { OrderService } from "src/app/services/order.service";
import { AuthService, AuthState } from "../../services/auth.service";
import { ToastrService } from "ngx-toastr";
import { StorageService } from "src/app/services/storage.service";

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.scss"],
})
export class OrdersComponent implements OnInit {
  order: Order;
  orderSelected: Order;
  customer: Customer;
  adding = false;
  itemAdded = false;
  orders: Order[] = [];
  loading = false;

  userState: AuthState;
  authSub: Subscription;
  viewDetails = false;

  constructor(
    private toastrService: ToastrService,
    private authService: AuthService,
    private api: API_CWSService,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private storage: StorageService
  ) { }

  ngOnInit(): void {
    this.authSub = this.authService.auth$.subscribe((userState) => {
      this.userState = userState;
      if (userState) {
        this.customer = this.authService.customer;
        this.GetOrders();
      }
    });
    if (this.route.queryParams) {
      this.route.queryParams.subscribe((params) => {
        if (params.adding) {
          this.adding = true;
        }
      });
    }
  }

  refreshData() {
    this.GetOrders(true);
  }

  GetOrders(forceGet: boolean = false) {
    this.loading = true;
    if (!this.authService.customer || !this.authService.customer.orders || forceGet) {
   
      this.api.OrdersByCustomerWithItems(this.userState.customerID)
        .then((data) => {
          this.orders = data.items as Order[];
        })
        .catch((err) => {
          console.log("error on OrdersByCustomer", err);
        })
        .finally(() => {
        this.loading = false;
      });
    } else {
      this.orders = this.authService.customer.orders.items as Order[];
      this.loading = false;
    }
  }

  AddNewOrder() {
    this.orderSelected = undefined;
    this.adding = true;
  }

  addOrder(order: Order) {
    this.itemAdded = true;
    this.orders.push(order);
    this.orderSelected = order;
    this.viewDetails = true;
    this.adding = true;
  }

  deleteClicked(order: Order) {
    this.order = Object.assign({} as CreateOrderInput, order);

    this.api
      .DeleteOrder({ id: this.order.id })
      .then(() => {
        this.orders = this.orders.filter((f) => f.id !== this.order.id);
        this.toastrService.success("order deleted");
      })
      .catch((err) => {
        this.toastrService.error("something went wrong, please try again");
      });
  }

  submitOrder(order: Order) {
    this.order = order;
    this.order.orderStatus = OrderStatus.ordered;
    this.orderService
      .AddUpdateOrder(this.order)
      .then((data) => {
        const index = this.orders.findIndex((f) => f.id === order.id);
        this.orders[index] = this.order;
        this.adding = false;
        this.toastrService.success(
          "Thank you for your order, Bend It will be in touch soon",
          "Order placed!"
        );
      })
      .catch((err) => {
        console.log(err);
        this.toastrService.error("something went wrong, please try again");
      });
  }

  cancelClicked() {
    this.adding = false;
    this.viewDetails = false;
    //this.GetOrders();
  }

  orderAdded(order: Order) {
    // this.adding = false;
    this.orders.push(order);
  }

  orderUpdated(order: Order) {
    this.adding = false;
    let origord = this.orders.find((f) => f.id === order.id);
    origord.orderNotes = order.orderNotes;
    origord.customerReference = order.customerReference;
  }

  orderDeleted() {
    this.adding = false;
    this.GetOrders();
  }

  ViewCurves(order: Order) {
    this.orderSelected = order;
    this.adding = true;
    this.viewDetails = true;
  }

  editClicked(order: Order) {
    this.orderSelected = order;
    this.adding = true;
  }
}
