<div class="content-page">


  <app-banner-content [theme]="'dark'" [size]="'narrow'">
    <div class="container p-5 ">
      <h1 class="col-12 d-flex justify-content-center">
        Hello&nbsp;<span class="text-white">{{ customer?.companyName }},</span>
      </h1>
      <div class="text-center">
        <span class="bannerHeader pt-0 mt-0">Welcome, Somfy Curtain Track Ordering now LIVE!</span>
      </div>
    </div>
  </app-banner-content>
  <div class="container">
    <div class="row text-center header-feature">
      <h1 class="col-12">{{customer?.companyName}} Account Home</h1>
      <span class="text-white">Online ordering of Vertical Headrails and Curtain Tracks</span>
    </div>
  </div>

  <div class="container mt-5">
    <div class="row">
      <div class="col-6 offset-3">

        <app-banner-icon-click [icon]="'fas fa-shopping-basket'" [title]="'Add a new Quote/Order'"
          [discription]="'Creatw a new quote and add items such as a vertical headrail or curtain track and accessories'"
          [route]="'/orders'" [params]="{ adding: true }" [buttonText]="'Start New Quote'"></app-banner-icon-click>
        <app-banner-icon-click [icon]="'fas fa-layer-group'" [title]="'View Orders'"
          [discription]="'View all of your orders in one place. You can add new quotes and orders here too.'"
          [route]="'/orders'" [params]="" [buttonText]="'View Orders'"></app-banner-icon-click>
        <app-banner-icon-click [icon]="'fas fa-user'" [title]="'Manage Account'"
          [discription]="'Manage all of your account details here, including, Name, Addresses and contact details'"
          [route]="'/account'" [params]="" [buttonText]="'Manage Account'"></app-banner-icon-click>

      </div>
    </div>

    <div class="container-fluid bg-white">

      <div class="row justify-content-center">
        <div class="col-md-4 my-auto text-center">
          <img class="img-fluid card-anim" src="/assets/images/benthin-logo-trident-blinds-wales-chester.png"
            alt="benthin logo" />
        </div>
        <div class="col-md-4 my-auto">
          <img class="img-fluid card-anim" src="/assets/images/somfy-logo.jpg" alt="somfy logo" />
        </div>
        <div class="col-md-4 my-auto text-center">
          <img class="img-fluid card-anim" src="/assets/images/logo-louvolite-white.jpg" alt="louvolite logo" />
        </div>
      </div>
    </div>


    <div class="row d-flex justify-content-center mt-5" *ngIf="orders?.length > 0">
      <div class="card text-center col-12 col-md-3">
        <div class="card-body">
          <h2 class="card-title">Quotes</h2>
          <p class="card-text my-4">
            You currently have {{ quotes.length }} quote<span *ngIf="quotes.length !== 1">s</span>
          </p>
          <button class="btn btn-outline-success" [routerLink]="['/orders']" [queryParams]="{ orderStatus: ['quote'] }"
            [disabled]="quotes.length === 0">
            See Quotes
          </button>
        </div>
      </div>
      <div class="card text-center col-12 col-md-3 mx-2">
        <div class="card-body">
          <h2 class="card-title">Current Orders</h2>
          <p class="card-text my-4">
            You currently have {{ current.length }} order<span *ngIf="current.length !== 1">s</span>
          </p>
          <button class="btn btn-outline-success" [routerLink]="['/orders']"
            [queryParams]="{ orderStatus: ['ordered', 'progress'] }" [disabled]="current.length === 0">
            See Orders
          </button>
        </div>
      </div>
      <div class="card text-center col-12 col-md-3">
        <div class="card-body">
          <h2 class="card-title">Completed Orders</h2>
          <p class="card-text my-4">
            You have had {{ delivered.length }} order<span *ngIf="delivered.length !== 1">s</span>
            delivered
          </p>
          <button class="btn btn-outline-success" [routerLink]="['/orders']"
            [queryParams]="{ orderStatus: ['delivered'] }" [disabled]="delivered.length === 0">
            See Delivered orders
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="container mt-5">
    <!-- <app-new-acc-guide *ngIf="orders && orders.length === 0"></app-new-acc-guide> -->
    <app-document-download></app-document-download>
  </div>

</div>